<template>
  <div>
    <eden-table-actions
      :title="`${formatFigure(pageData.meta.total)} Activities`"
      :show-filter="false"
      @search="search"
    >
      <!-- <eden-table-actions :title="`${formatFigure(pageData.meta.total)} Activities`" :show-filter="false" :show-search="false"> -->
      <template slot="actions">
        <el-dropdown class="dropdown-with-buttons" @command="setPeriod">
          <el-button type="primary" plain>
            {{ periods[period]
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(value, key, i) in periods"
              :key="i"
              :command="key"
              :class="{ selected: period === key }"
              >{{ value }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </template>
    </eden-table-actions>
    <eden-loader v-if="loading" :cols="7" type="four-columns-table" />
    <template v-else>
      <div
        v-if="showCustomTitle && period === 'custom'"
        class="my-1 is-flex is-align-center is-justify-center"
      >
        <p>
          Showing For {{ formatDate(form.dateRange[0], "do m, y ") }} -
          {{ formatDate(form.dateRange[1], "do m, y") }}
        </p>
      </div>
      <el-table :data="pageData.data" empty-text="No Activities">
        <el-table-column width="80">
          <template slot-scope="scope">
            <eden-activity-icon :activity="scope.row.event" />
          </template>
        </el-table-column>
        <el-table-column prop="user_logger_fullname" width="180">
          <template slot="header">
            <span class="eden-table-header">
              User
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p v-if="scope.row.user_id?.name" class="text-black">
              {{ scope.row.user_id?.name }}
            </p>
            <p class="text-black" v-else>The System</p>
          </template>
        </el-table-column>
        <el-table-column prop="description">
          <template slot="header">
            <span class="eden-table-header">
              Description
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ formatToTitleCase(scope.row.description) }}
              <!-- <span v-if="getActivityType(scope.row.new_values)">{{
                    getActivityType(scope.row.new_values)
                  }}</span> -->
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" width="200">
          <template slot="header">
            <span class="eden-table-header">
              Date & Time
              <i class="el-icon-bottom"></i>
            </span>
          </template>
          <template slot-scope="scope">
            <p>{{ formatDateAndTime(scope.row.updated_at, "do m, y") }}</p>
          </template>
        </el-table-column>
        <el-table-column width="60">
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon--more-vertical"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-info"
                  :command="{ action: 'view', index: scope.$index }"
                  >More info</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="pageData.data.length && !hidePagination"
        :from="pageData.meta.from"
        :to="pageData.meta.to"
        :total="pageData.meta.total"
        :current-page.sync="page"
        :page-size="pageData.meta.per_page"
      />
    </template>
    <activity-details :show.sync="showActivity" :activity="activity" />
    <el-dialog
      title="Activities For"
      :visible.sync="showCustomDate"
      @close="closeEvent"
      width="30%"
    >
      <el-form :model="form" label-position="top">
        <el-row type="flex">
          <el-col :span="24">
            <el-form-item label="Range" prop="dateRange">
              <el-date-picker
                v-model="form.dateRange"
                type="daterange"
                range-separator="-"
                start-placeholder="From"
                end-placeholder="To"
                format="dd MMM, yyyy"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="closeEvent">Cancel</el-button>
        <el-button
          type="primary"
          @click="getActivities"
          :disabled="form.dateRange.length == 0"
          >Get</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ActivityDetails from "@/components/Activities/ActivityDetails";
import * as actions from "@/store/action-types";
import activities from "@/requests/activities";

export default {
  name: "AllActivities",
  components: {
    ActivityDetails,
  },
  data() {
    return {
      loading: false,
      period: "thisweek",
      periods: {
        last90: "Last 90 days",
        last60: "Last 60 days",
        last30: "Last 30 days",
        lastmonth: "Last month",
        lastweek: "Last week",
        thisweek: "This week",
        thismonth: "This month",
        custom: "Custom date",
      },
      page: 1,
      pageData: {
        meta: {
          from: null,
          to: null,
          total: 0,
        },
        data: [],
      },
      hidePagination: false,
      showCustomDate: false,
      showCustomTitle: false,
      form: {
        dateRange: [],
        from: "",
        to: "",
      },
      filterItems: {
        period: {
          label: "Period",
          type: "dates",
          start: "",
          end: "",
        },
      },
      activity: {},
      showActivity: false,
    };
  },
  watch: {
    page() {
      if (!this.$store.getters.activities[this.period][this.page]) {
        this.getActivities();
      } else {
        this.setCurrentPageData();
      }
    },
    period() {
      if (this.period === "custom") {
        this.showCustomDate = true;
      } else {
        if (!this.$store.getters.activities[this.period]) {
          this.page = 1;
          this.getActivities();
        } else {
          this.setCurrentPageData();
        }
      }
    },
  },
  created() {
    this.getActivities();
  },
  methods: {
    setPeriod(period) {
      this.period = period;
    },
    setCurrentPageData() {
      this.pageData = {
        ...this.$store.getters.activities[this.period][this.page],
      };
      this.hidePagination = false;
      this.showFilters = false;
    },
    closeEvent() {
      if (!this.showCustomTitle) {
        this.period = "thisweek";
      }
      this.showCustomDate = false;
    },
    getActivities() {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period: this.period,
        from: this.form.dateRange[0],
        to: this.form.dateRange[1],
      });
      this.$store
        .dispatch(actions.GET_ACTIVITIES, {
          period: this.period,
          start_date,
          end_date,
          page: this.page,
        })
        .then(() => {
          this.setCurrentPageData();
          this.loading = false;
          this.showCustomDate = false;
          this.showCustomTitle = true;
        });
    },
    search(searchQuery) {
      if (searchQuery === "") {
        this.setCurrentPageData();
        return;
      }
      this.loading = true;
      activities
        .search(searchQuery)
        .then((response) => {
          this.pageData.data = response.data.data;
          this.hidePagination = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getActivitiesWithFilters() {
      this.form.from = this.form.dateRange[0];
      this.form.to = this.form.dateRange[1];

      const payload = {
        from_date: this.form.from,
        to_date: this.form.to,
      };
      this.loading = true;
      activities
        .filter(payload)
        .then((response) => {
          this.pageData.data = response.data.data;
          this.hidePagination = true;
          this.showFilters = true;
          this.showCustomDate = false;
          this.showCustomTitle = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getActivityType(activity) {
      if (!activity) {
        return "";
      }
      if (activity.includes("frequency")) {
        return "(Plan)";
      } else if (activity.includes("status")) {
        return "(Status)";
      } else {
        return "";
      }
    },
    command(command) {
      if (command.action === "view") {
        this.activity = this.pageData.data[command.index];
        this.showActivity = true;
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
