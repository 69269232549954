<template>
  <div>
    <eden-page-header :title="'Activities'" />
    <all-activities />
  </div>
</template>

<script>
import AllActivities from "@/components/Activities/AllActivities";
export default {
  name: "Activities",
  components: {
    AllActivities
  },
  data() {
    return {};
  },
};
</script>
