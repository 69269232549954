<template>
  <el-dialog :visible.sync="shouldShow" @close="closeEvent" width="30%">
    <template slot="title">
      <eden-activity-icon :activity="`${activity.event}`" />
    </template>
    <el-row type="flex">
      <el-col :md="12">
        <p>Performed by</p>
        <span>{{ activity.user_logger_fullname || "The System" }}</span>
      </el-col>
      <el-col :md="12" class="is-flex is-flex-column is-align-end">
        <p>Date & Time</p>
        <span>{{ formatDateAndTime(activity.created_at, "do m,y") }}</span>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <p>Description</p>
        <span>{{ formatToTitleCase(activity.event_description) }}</span>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <p>IP Address</p>
        <span>{{ activity.ip_address }}</span>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <p>User Agent</p>
        <span>{{ activity.user_agent }}</span>
      </el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  name: "ActivityDetails",
  props: {
    show: Boolean,
    activity: Object,
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  methods: {
    closeEvent() {
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #21312a;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 5px;
}

p {
  font-size: 0.75rem;
  margin-bottom: 5px;
}

span {
  font-size: 0.875rem;
  color: #21312a;
  line-height: 1.5;
}

.el-tag {
  height: 26px;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
}
</style>
